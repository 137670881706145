/* @import url("https://fonts.googleapis.com/css2?family=Roboto"); */
@font-face {
	font-family: "Tahoma";
	src: local("Tahoma"), url("../fonts/TAHOMA_0.TTF") ;
	font-family: "Tahoma Bold";
	src: local("Tahoma Bold"), url("../fonts/TAHOMAB0.TTF") ;
}
body {
  font-family: "Tahoma" !important;
}

option {
  color: black;
}
.primary-bg{
	background-color: #B0D1AE;
}
.secondary-bg{
	background-color: #DBA781;
}
