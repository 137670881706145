.container-notification {
  border-radius: 10px;
  .ant-drawer-body {
    padding: 0 !important;
  }
  :where(.css-dev-only-do-not-override-2q8sxy).ant-drawer .ant-drawer-body {
    padding: 0 !important;
  }
  // .custom-drawer .ant-drawer-content {
  //   border: 2px solid #f5ca1c !important; // Ví dụ: đường viền màu vàng
  //   border-radius: 10px !important;
  // }

  /* Thu nhỏ thanh cuộn cho thẻ div */
}
.custom-tabs {
  .ant-tabs-nav-list {
    font-weight: 600 !important;
  }
  .ant-tabs-nav {
    border-bottom: none !important;
  }
}

/* Thu nhỏ thanh cuộn cho thẻ div */
.custom-scroll::-webkit-scrollbar {
  width: 6px; /* Độ rộng của thanh cuộn dọc */
}

.custom-scroll::-webkit-scrollbar-thumb {
  background-color: #888; /* Màu của thanh cuộn */
  border-radius: 10px; /* Bo tròn góc của thanh cuộn */
}

.custom-scroll::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Màu nền của track thanh cuộn */
}

// :where(.css-dev-only-do-not-override-2q8sxy).ant-drawer .ant-drawer-content {
//   border-radius: 10px !important;
// }
// :where(.css-dev-only-do-not-override-2q8sxy).ant-drawer .ant-drawer-body {
//   flex: 1;
//   min-width: 0;
//   min-height: 0;
//   padding: 0px !important;
//   overflow: auto;
// }
