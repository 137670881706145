.container-order {
  .box-product {
    box-shadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)" !important;
    margin-bottom: 20 !important;
  }
  .ant-tabs-nav {
    margin-top: 10px !important;
    margin-bottom: 0px !important;
  }
  .ant-tabs-content-holder {
    margin-top: 10px;
    margin-bottom: 40px;
  }
  .input-order::placeholder {
    color: rgba(0, 0, 0, 0.25);
  }
  :where(.css-dev-only-do-not-override-2q8sxy).ant-input:placeholder-shown {
    background-color: #f5f5f5 !important;
  }
  :where(.css-dev-only-do-not-override-2q8sxy).ant-input {
    background-color: #f5f5f5 !important;
  }

  // .custom-tab-bar {
  //   display: flex;
  //   justify-content: space-around;
  //   border-bottom: 1px solid #e8e8e8;
  //   background-color: #fff;

  //   a {
  //     text-decoration: none;
  //     padding: 12px 16px;
  //     color: rgba(0, 0, 0, 0.85);
  //     font-weight: 500;
  //     transition: color 0.3s;

  //     &:hover {
  //       color: #1890ff; // Default hover color in Ant Design
  //     }

  //     &.active {
  //       color: #1890ff;
  //       border-bottom: 2px solid #1890ff; // Active tab indicator
  //     }

  //     div {
  //       // Ensuring div inside the anchor tag takes up the correct space
  //       display: flex;
  //       align-items: center;
  //       justify-content: center;
  //     }
  //   }
  // }

  .custom-tab-bar {
    display: flex;
    justify-content: space-around;
    border-bottom: 1px solid #e8e8e8;
    background-color: #fff;
    overflow-x: auto; // Cho phép cuộn ngang
    white-space: nowrap; // Ngăn ngừa xuống hàng

    a {
      text-decoration: none;
      padding: 12px 16px;
      color: rgba(0, 0, 0, 0.85);
      font-weight: 500;
      transition: color 0.3s;
      display: inline-block; // Giữ các tab nằm trên cùng một hàng

      &:hover {
        color: #1890ff;
      }

      &.active {
        color: #1890ff;
        // border-bottom: 2px solid #1890ff;
      }

      div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px; // Đảm bảo chiều rộng của div bên trong tab
      }
    }
  }
  .active {
    color: pink !important;
  }
}
.drawer-DG {
  :where(.css-dev-only-do-not-override-2q8sxy).ant-input:placeholder-shown {
    background-color: #f5f5f5 !important;
  }
  :where(.css-dev-only-do-not-override-2q8sxy).ant-input {
    background-color: #f5f5f5 !important;
  }
}
