.container-home-order {
  padding: 5%;

  :where(.css-dev-only-do-not-override-2q8sxy).ant-btn-default:not(:disabled):not(
    .ant-btn-disabled
  ):hover {
  color: black;
}
.title-home-order-p {
  color: #363636;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  // letter-spacing: 0;
  // text-align: left;
  // display: -webkit-box;
  // display: -ms-flexbox;
  // display: flex;
  // -webkit-box-align: center;
  // -ms-flex-align: center;
}

.container-bonus {
  background-image: url(https://o2o.ipos.vn/static/images/img_background_card.svg);
  background-size: cover;
  margin-top: 8px;
  background-repeat: no-repeat;
  position: relative;
  border-radius: 12px 12px 0 0;
  border: solid #d6dee9;
  border-width: 1px 1px 0;
  padding: 16px 16px 0px;
}
.bonus-points {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
  color: #3b526d;
}

.bonus-points2 {
  font-style: normal;
  font-weight: 600;
  // line-height: 24px;
  letter-spacing: 0;
  text-align: left;
  color: #3b526d;
  margin-bottom: 0px;
}
.box-icons {
  padding: 10px 0;
  background-color: #fff !important;
  border-radius: 10px;
  position: relative;
  -webkit-box-shadow: 0 0 8px rgba(63, 63, 70, 0.1);
  box-shadow: 0 0 8px rgba(63, 63, 70, 0.1);
}
.box-icons2 {
  padding: 15px;
  background-color: #e84545;
  border-radius: 15px;
  -webkit-box-shadow: 0 0 8px rgba(63, 63, 70, 0.1);
  box-shadow: 0 0 8px rgba(63, 63, 70, 0.1);
}

}
