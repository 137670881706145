.p-calendar{
    border: 1px solid #ced4da !important;
    border-radius: 3px;
    padding: 4px;
}
.p-datepicker{
    background-color: white;
    padding: 20px;
    tbody{
        tr{
            td{
                padding: 4px 6px;
            }
        }
    }
}
.giai-dau-detail{
    .ant-drawer-body{
        padding-top: 0px ;
    }
}