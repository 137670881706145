$desktop-width-2160: 3840px;
$desktop-width-1920: 1920px;
$desktop-width-1440: 1440px;
$desktop-width-1098: 1100px;
.container-detail-gd {
  height: 400px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  // align-items: center;

  h1,
  h2,
  h3,
  h4 {
    color: yellow;
  }
  .timenow {
    position: absolute;
    left: 10px;
    top: 10px;
    font-size: 20px;
    color: white;
    font-weight: bold;
  }
  .center {
    // height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .title {
    font-size: 20px;
    padding-top: 20px;
    font-weight: "600";
    width: 80%;
    text-align: center;
    line-height: normal;
  }
  .time {
    font-size: 50px;
    font-weight: 900;
  }
  .level {
    font-size: 20px;
    font-weight: 900;
  }
  .bigsmall {
    display: flex;
    gap: 5;
    justify-content: center;
    align-items: center;
  }
  .textContent {
    font-size: 20px;
    text-align: center;
    font-weight: 700;
  }
  .textvalue {
    font-size: 25px;
    text-align: center;
    font-weight: 900;
  }
  .nextblind {
    font-size: 20px;
    text-align: center;
    color: white;
    font-weight: 900;
  }
  .right {
    position: absolute;
    right: 10px;
    height: 100%;
    top: 0;
    .right-center {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-align: center;
      padding-top: 10px;
      padding-bottom: 50px;
      .rigth-text {
        font-size: 15px;
        font-weight: 400;
        color: white;
        margin-top: 10px;
      }
      .rigth-value {
        font-size: 15px;
        font-weight: 900;
        color: white;
      }
    }
  }

  .box-left {
    position: absolute;
    left: 10px;
    // height: 100%;
    .centerLeft {
      // height: 100%;
      align-items: flex-start;
      justify-content: flex-start;
      padding-top: 50px;
      .totalPrize {
        color: white;
        font-size: 15px;
      }
      .totalPrizeValue {
        color: white;
        font-size: 20px;
        font-weight: 900;
      }
      .textRank {
        color: white;
        font-size: 30px;
        span {
          font-weight: bold;
        }
      }
    }
  }
}
.container-detail-gd2 {
  height: 400px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: white;
  position: relative;
  .timenow {
    position: absolute;
    left: 10px;
    top: 10px;
    font-size: 20px;
    font-weight: bold;
  }
  .top {
    height: 25%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    .title {
      font-size: 20px;
      font-weight: 700;
    }
    .sub-title {
      font-size: 15px;
      font-weight: 500;
    }
    .level {
      font-size: 20px;
      font-weight: 900;
    }
  }
  .middle {
    height: 75%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 20%;
      padding: 0px 15px;
      .top-left {
        height: 80%;
        .giai {
          font-size: 25px;
          font-weight: 400;
          span {
            font-weight: 900;
          }
        }
      }
      .footer-left {
        height: 20%;
        text-align: right;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        .total-prize-text {
          font-size: 15px;
          font-weight: 500;
        }
        .total-prize {
          font-size: 20px;
          font-weight: 900;
        }
      }
    }
    .center {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-left: 1px solid;
      border-right: 1px solid;
      width: 60%;
      padding: 0px 20px;
      .top-center {
        .time {
          font-size: 70px;
          font-weight: 900;
          margin-top: -20px;
        }
        .blinds {
          display: flex;
          justify-content: space-between;
          padding: 0px 100px;
          .text-blind {
            font-size: 20px;
            font-weight: 700;
          }
          .text-level {
            padding-top: 10px;
            font-size: 15px;
            font-weight: 700;
          }
        }
      }
      .footer-center {
        display: flex;
        justify-content: space-between;
        .starting-text {
          font-size: 15px;
          font-weight: 400;
        }
        .starting {
          font-size: 20px;
          font-weight: 900;
        }
      }
    }
    .right {
      width: 20%;
      padding-left: 20px;
      .right-text {
        font-size: 15px;
        font-weight: 400;
      }
      .right-value {
        font-size: 20px;
        font-weight: 900;
        padding-bottom: 10px;
      }
    }
  }
}
.container-detail-gd3 {
  height: 400px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: white;
  display: flex;
  flex-direction: column;
  position: relative;
  .timenow {
    position: absolute;
    left: 10px;
    top: 10px;
    font-size: 20px;
    font-weight: bold;
  }
  .top {
    height: 25%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    .title {
      font-size: 20px;
      font-weight: 700;
    }
    .sub-title {
      font-size: 15px;
      font-weight: 500;
    }
    .level {
      font-size: 20px;
      font-weight: 900;
    }
  }
  .middle {
    height: 75%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 20%;
      padding: 0px 15px;
      .top-left {
        height: 80%;
        .giai {
          font-size: 15px;
          font-weight: 400;
          span {
            font-weight: 900;
          }
        }
      }
      .footer-left {
        height: 20%;
        text-align: right;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        .total-prize-text {
          font-size: 15px;
          font-weight: 500;
        }
        .total-prize {
          font-size: 20px;
          font-weight: 900;
        }
      }
    }
    .center {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-left: 1px solid;
      border-right: 1px solid;
      width: 60%;
      padding: 0px 20px;
      .top-center {
        .time {
          font-size: 70px;
          font-weight: 900;
          margin-top: -40px;
          color: yellow;
        }
        .blinds {
          display: flex;
          justify-content: space-between;
          padding: 0px 100px;
          color: yellow;
          .text-blind {
            font-size: 20px;
            font-weight: 700;
          }
          .text-level {
            padding-top: 10px;
            font-size: 15px;
            font-weight: 700;
          }
        }
        .level {
          padding-top: 20px;
          font-size: 20px;
          font-weight: 900;
        }
      }
      .footer-center {
        display: flex;
        justify-content: space-between;
        .starting-text {
          font-size: 15px;
          font-weight: 400;
        }
        .starting {
          font-size: 20px;
          font-weight: 900;
        }
      }
    }
    .right {
      width: 20%;
      padding-left: 20px;
      .right-text {
        font-size: 15px;
        font-weight: 400;
      }
      .right-value {
        font-size: 20px;
        font-weight: 900;
        padding-bottom: 10px;
      }
    }
  }
}
