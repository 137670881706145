.container-login-order {
  padding: 5%;
  :where(.css-dev-only-do-not-override-2q8sxy).ant-btn-default:not(
      :disabled
    ):not(.ant-btn-disabled):hover {
    color: #fff;
    border-color: #fff;
  }
  :where(.css-dev-only-do-not-override-2q8sxy).ant-btn-default:not(
      :disabled
    ):not(.ant-btn-disabled):hover {
    color: black;
  }
}

.title-h3 {
  color: #363636;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0;
  text-align: left;
  margin-top: 20px;
}
.title-p {
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
  margin-top: 20px;
}
.input-order {
  display: block;
  width: 100%;
  border: 0;
  background-color: #f5f5f5;
  outline: 0;
  padding: 8px 18px;
  font-size: 15px;
  border-radius: 10px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  resize: none;
  height: 50px;
}
.button-order {
  margin: 15px 0px;
  background-color: #f7941e;
  border: 0;
  color: #fff;
  font-weight: 500;
  height: 42px;
  line-height: 1;
  outline: 0;
  font-size: 16px;
  border-radius: 8px;
  width: 100%;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
