.ant-modal-content{
    // height: 100vh;
}
.input-number-custom{
   
    .ant-input-number-input{
        font-size: 20px !important;
        font-weight: bolder;
        color:red;
    }
}