$desktop-width-3840: 3840px;
$desktop-width-1920: 1920px;
$desktop-width-1440: 1440px;
$desktop-width-1098: 1100px;
.container-screen1 {
  height: 100vh;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1,
  h2,
  h3,
  h4 {
    color: yellow;
  }
  .timenow {
    position: absolute;
    left: 10px;
    top: 10px;
    font-size: 30px;
    font-weight: bold;
  }
  @media all and (max-width: $desktop-width-1920) {
    .center {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
    }
    .title {
      font-size: 40px;
      font-weight: 900;
      width: 80%;
      text-align: center;
      line-height: normal;
    }
    .time {
      font-size: 200px;
      font-weight: 900;
    }
    .level {
      font-size: 70px;
      font-weight: 900;
      margin-top: -50px;
    }
    .bigsmall {
      display: flex;
      gap: 5;
      justify-content: center;
      align-items: center;
    }
    .textContent {
      font-size: 50px;
      text-align: center;
      font-weight: 700;
      margin-top: -20px;
    }
    .textvalue {
      font-size: 70px;
      text-align: center;
      font-weight: 900;
    }
    .nextblind {
      font-size: 50px;
      text-align: center;
      color: white;
      font-weight: 900;
    }
    .right {
      position: absolute;
      right: 10px;
      height: 100%;
      top: 0;
      .right-center {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-align: center;
        padding-top: 100px;
        padding-bottom: 50px;
        .rigth-text {
          font-size: 40px;
          font-weight: 100;
          color: white;
          padding-top: 20px;
        }
        .rigth-value {
          font-size: 50px;
          font-weight: 900;
          color: white;
          margin-top: -15px;
        }
      }
    }

    .box-left {
      position: absolute;
      left: 10px;
      .centerLeft {
        height: 100%;
        align-items: flex-start;
        justify-content: flex-start;
        .totalPrize {
          margin-top: 50px;
          color: white;
          font-size: 40px;
        }
        .totalPrizeValue {
          color: white;
          font-size: 50px;
          font-weight: 900;
        }
        .textRank {
          color: white;
          font-size: 35px;
          span {
            font-weight: bold;
          }
        }
      }
    }
  }
  @media all and (max-width: $desktop-width-1440) {
    .center {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
    }
    .title {
      font-size: 30px;
      font-weight: "100";
      width: 80%;
      text-align: center;
      line-height: normal;
    }
    .time {
      font-size: 180px;
      font-weight: 900;
    }
    .level {
      font-size: 50px;
      font-weight: 900;
      margin-top: -50px;
    }
    .bigsmall {
      display: flex;
      gap: 5;
      justify-content: center;
      align-items: center;
    }
    .textContent {
      font-size: 35px;
      text-align: center;
      font-weight: 700;
      margin-top: -20px;
    }
    .textvalue {
      font-size: 60px;
      text-align: center;
      font-weight: 900;
    }
    .nextblind {
      font-size: 35px;
      text-align: center;
      color: white;
      font-weight: 900;
    }
    .right {
      position: absolute;
      right: 10px;
      height: 100%;
      top: 0;
      .right-center {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-align: center;
        padding-top: 100px;
        padding-bottom: 50px;
        .rigth-text {
          font-size: 25px;
          font-weight: 400;
          color: white;
          padding-top: 20px;
        }
        .rigth-value {
          font-size: 30px;
          font-weight: 900;
          color: white;
          margin-top: -15px;
        }
      }
    }

    .box-left {
      position: absolute;
      left: 10px;
      height: 100%;
      .centerLeft {
        height: 100%;
        align-items: flex-start;
        justify-content: flex-start;
        padding-top: 50px;
        .totalPrize {
          color: white;
          font-size: 30px;
        }
        .totalPrizeValue {
          color: white;
          font-size: 40px;
          font-weight: 900;
        }
        .textRank {
          color: white;
          font-size: 30px;
          span {
            font-weight: bold;
          }
        }
      }
    }
  }
  @media all and (max-width: $desktop-width-1098) {
    .center {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
    }
    .title {
      font-size: 20px;
      font-weight: 900;
      width: 80%;
      text-align: center;
      line-height: normal;
    }
    .time {
      font-size: 140px;
      font-weight: 900;
    }
    .level {
      font-size: 30px;
      font-weight: 900;
      margin-top: -50px;
    }
    .bigsmall {
      display: flex;
      gap: 5;
      justify-content: center;
      align-items: center;
    }
    .textContent {
      font-size: 25px;
      text-align: center;
      font-weight: 700;
      margin-top: -10px;
    }
    .textvalue {
      font-size: 40px;
      text-align: center;
      font-weight: 900;
    }
    .nextblind {
      font-size: 25px;
      text-align: center;
      color: white;
      font-weight: 900;
    }
    .right {
      position: absolute;
      right: 10px;
      height: 100%;
      top: 0;
      .right-center {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-align: center;
        padding-top: 100px;
        padding-bottom: 50px;
        .rigth-text {
          font-size: 18px;
          font-weight: 400;
          color: white;
          padding-top: 20px;
        }
        .rigth-value {
          font-size: 22px;
          font-weight: 900;
          color: white;
          margin-top: 0px;
        }
      }
    }

    .box-left {
      position: absolute;
      left: 10px;
      height: 100%;
      .centerLeft {
        height: 100%;
        align-items: flex-start;
        justify-content: flex-start;
        padding-top: 50px;
        .totalPrize {
          color: white;
          font-size: 25px;
        }
        .totalPrizeValue {
          color: white;
          font-size: 35px;
          font-weight: 900;
        }
        .textRank {
          color: white;
          font-size: 20px;
          span {
            font-weight: bold;
          }
        }
      }
    }
  }
}
.container-screen2 {
  height: 100vh;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: white;
  .timenow {
    position: absolute;
    left: 10px;
    top: 10px;
    font-size: 30px;
    font-weight: bold;
  }
  @media all and (max-width: $desktop-width-3840) {
    .top {
      height: 25%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: center;
      .title {
        font-size: 40px;
        font-weight: 700;
      }
      .sub-title {
        font-size: 30px;
        font-weight: 500;
      }
      .level {
        font-size: 45px;
        font-weight: 900;
      }
    }
    .middle {
      height: 75%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .left {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 20%;
        padding: 0px 20px;
        .top-left {
          height: 80%;
          // overflow: auto;
          .giai {
            font-size: 30px;
            font-weight: 400;
            span {
              font-weight: 900;
            }
          }
        }
        .footer-left {
          height: 20%;
          text-align: right;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          .total-prize-text {
            font-size: 35px;
            font-weight: 500;
          }
          .total-prize {
            font-size: 45px;
            font-weight: 900;
          }
        }
      }
      .center {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-left: 1px solid;
        border-right: 1px solid;
        width: 60%;
        padding: 0px 20px;
        .top-center {
          .time {
            font-size: 130px;
            font-weight: 900;
            margin-top: -60px;
          }
          .blinds {
            display: flex;
            justify-content: space-between;
            padding: 0px 100px;
            .text-blind {
              font-size: 50px;
              font-weight: 700;
            }
            .text-level {
              padding-top: 30px;
              font-size: 35px;
              font-weight: 700;
            }
          }
          .level {
            padding-top: 5%;
            font-size: 55px;
            font-weight: 700;
          }
        }
        .footer-center {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .starting-text {
            font-size: 35px;
            font-weight: 400;
          }

          .starting {
            font-size: 45px;
            font-weight: 900;
          }
        }
      }
      .right {
        width: 20%;
        padding-left: 20px;
        .right-text {
          font-size: 35px;
          font-weight: 400;
        }
        .right-value {
          font-size: 45px;
          font-weight: 900;
          margin-top: -10px;
          padding-bottom: 30px;
        }
      }
    }
  }
  @media all and (max-width: $desktop-width-1920) {
    .top {
      height: 25%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: center;
      .title {
        font-size: 45px;
        font-weight: 700;
      }
      .sub-title {
        font-size: 35px;
        font-weight: 500;
      }
      .level {
        font-size: 45px;
        font-weight: 900;
      }
    }
    .middle {
      height: 75%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .left {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 20%;
        padding: 0px 20px;
        .top-left {
          height: 80%;
          // overflow: auto;
          .giai {
            font-size: 30px;
            font-weight: 400;
            span {
              font-weight: 900;
            }
          }
        }
        .footer-left {
          height: 20%;
          text-align: right;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          .total-prize-text {
            font-size: 35px;
            font-weight: 500;
          }
          .total-prize {
            font-size: 45px;
            font-weight: 900;
          }
        }
      }
      .center {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-left: 1px solid;
        border-right: 1px solid;
        width: 60%;
        padding: 0px 20px;
        .top-center {
          .time {
            font-size: 180px;
            font-weight: 900;
            margin-top: -50px;
          }
          .blinds {
            display: flex;
            justify-content: space-between;
            padding: 0px 100px;
            .text-blind {
              font-size: 55px;
              font-weight: 700;
            }
            .text-level {
              padding-top: 25px;
              font-size: 35px;
              font-weight: 700;
            }
          }
        }
        .footer-center {
          display: flex;
          justify-content: space-between;
          .starting-text {
            font-size: 35px;
            font-weight: 400;
          }
          .starting {
            font-size: 45px;
            font-weight: 900;
          }
        }
      }
      .right {
        width: 20%;
        padding-left: 20px;
        .right-text {
          font-size: 35px;
          font-weight: 400;
        }
        .right-value {
          font-size: 45px;
          font-weight: 900;
          margin-top: -10px;
          padding-bottom: 30px;
        }
      }
    }
  }
  @media all and (max-width: $desktop-width-1440) {
    .top {
      height: 25%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: center;
      .title {
        font-size: 35px;
        font-weight: 700;
      }
      .sub-title {
        font-size: 25px;
        font-weight: 500;
      }
      .level {
        font-size: 35px;
        font-weight: 900;
      }
    }
    .middle {
      height: 75%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .left {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 20%;
        padding: 0px 15px;
        .top-left {
          height: 80%;
          .giai {
            font-size: 25px;
            font-weight: 400;
            span {
              font-weight: 900;
            }
          }
        }
        .footer-left {
          height: 20%;
          text-align: right;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          .total-prize-text {
            font-size: 25px;
            font-weight: 500;
          }
          .total-prize {
            font-size: 35px;
            font-weight: 900;
          }
        }
      }
      .center {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-left: 1px solid;
        border-right: 1px solid;
        width: 60%;
        padding: 0px 20px;
        .top-center {
          .time {
            font-size: 160px;
            font-weight: 900;
            margin-top: -40px;
          }
          .blinds {
            display: flex;
            justify-content: space-between;
            padding: 0px 100px;
            .text-blind {
              font-size: 45px;
              font-weight: 700;
            }
            .text-level {
              padding-top: 25px;
              font-size: 25px;
              font-weight: 700;
            }
          }
        }
        .footer-center {
          display: flex;
          justify-content: space-between;
          .starting-text {
            font-size: 25px;
            font-weight: 400;
          }
          .starting {
            font-size: 35px;
            font-weight: 900;
          }
        }
      }
      .right {
        width: 20%;
        padding-left: 20px;
        .right-text {
          font-size: 25px;
          font-weight: 400;
        }
        .right-value {
          font-size: 35px;
          font-weight: 900;
          margin-top: -10px;
          padding-bottom: 30px;
        }
      }
    }
  }
  @media all and (max-width: $desktop-width-1098) {
    .top {
      height: 25%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: center;
      .title {
        font-size: 25px;
        font-weight: 700;
      }
      .sub-title {
        font-size: 20px;
        font-weight: 500;
      }
      .level {
        font-size: 25px;
        font-weight: 900;
      }
    }
    .middle {
      height: 75%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .left {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 20%;
        padding: 0px 20px;
        .top-left {
          height: 80%;
          .giai {
            font-size: 15px;
            font-weight: 400;
            span {
              font-weight: 900;
            }
          }
        }
        .footer-left {
          height: 20%;
          text-align: right;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          .total-prize-text {
            font-size: 15px;
            font-weight: 500;
          }
          .total-prize {
            font-size: 20px;
            font-weight: 900;
          }
        }
      }
      .center {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-left: 1px solid;
        border-right: 1px solid;
        width: 60%;
        padding: 0px 20px;
        .top-center {
          .time {
            font-size: 120px;
            font-weight: 900;
            margin-top: -40px;
          }
          .blinds {
            display: flex;
            justify-content: space-between;
            padding: 0px 100px;
            .text-blind {
              font-size: 25px;
              font-weight: 700;
            }
            .text-level {
              padding-top: 15px;
              font-size: 20px;
              font-weight: 700;
            }
          }
        }
        .footer-center {
          display: flex;
          justify-content: space-between;
          .starting-text {
            font-size: 20px;
            font-weight: 400;
          }
          .starting {
            font-size: 20px;
            font-weight: 900;
          }
        }
      }
      .right {
        width: 20%;
        padding-left: 20px;
        .right-text {
          font-size: 15px;
          font-weight: 400;
        }
        .right-value {
          font-size: 25px;
          font-weight: 900;
          margin-top: -10px;
          padding-bottom: 30px;
        }
      }
    }
  }
}
.container-screen3 {
  height: 100vh;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: white;
  .timenow {
    position: absolute;
    left: 10px;
    top: 10px;
    font-size: 30px;
    font-weight: bold;
  }
  @media all and (min-width: $desktop-width-3840) {
    .top {
      height: 25%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: center;
      .title {
        font-size: 45px;
        font-weight: 700;
      }
      .sub-title {
        font-size: 30px;
        font-weight: 500;
      }
      .level {
        font-size: 50px;
        font-weight: 900;
      }
    }
    .middle {
      height: 75%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .left {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 20%;
        padding: 0px 20px;
        .top-left {
          height: 80%;
          // overflow: auto;
          .giai {
            font-size: 35px;
            font-weight: 400;
            span {
              font-weight: 900;
            }
          }
        }
        .footer-left {
          height: 20%;
          text-align: right;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          .total-prize-text {
            font-size: 40px;
            font-weight: 500;
          }
          .total-prize {
            font-size: 50px;
            font-weight: 900;
          }
        }
      }
      .center {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-left: 1px solid;
        border-right: 1px solid;
        width: 60%;
        padding: 0px 20px;
        .top-center {
          .time {
            font-size: 130px;
            font-weight: 900;
            margin-top: -80px;
            color: yellow;
          }
          .blinds {
            display: flex;
            justify-content: space-between;
            padding: 0px 100px;
            color: yellow;
            .text-blind {
              font-size: 50px;
              font-weight: 700;
            }
            .text-level {
              padding-top: 20px;
              font-size: 40px;
              font-weight: 700;
            }
          }
          .level {
            padding-top: -3%;
            font-size: 60px;
            font-weight: 700;
          }
        }
        .footer-center {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .starting-text {
            font-size: 40px;
            font-weight: 400;
          }

          .starting {
            font-size: 50px;
            font-weight: 900;
          }
        }
      }
      .right {
        width: 20%;
        padding-left: 20px;
        .right-text {
          font-size: 40px;
          font-weight: 400;
        }
        .right-value {
          font-size: 50px;
          font-weight: 900;
          margin-top: -10px;
          padding-bottom: 30px;
        }
      }
    }
  }
  @media all and (max-width: $desktop-width-3840) and (min-width: $desktop-width-1920) {
    // @media all and ( max-width: $desktop-width-3840) {
    .top {
      height: 25%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: center;
      .title {
        font-size: 40px;
        font-weight: 700;
      }
      .sub-title {
        font-size: 30px;
        font-weight: 500;
      }
      .level {
        font-size: 45px;
        font-weight: 900;
      }
    }
    .middle {
      height: 75%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .left {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 20%;
        padding: 0px 20px;
        .top-left {
          height: 80%;
          // overflow: auto;
          .giai {
            font-size: 30px;
            font-weight: 400;
            span {
              font-weight: 900;
            }
          }
        }
        .footer-left {
          height: 20%;
          text-align: right;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          .total-prize-text {
            font-size: 35px;
            font-weight: 500;
          }
          .total-prize {
            font-size: 45px;
            font-weight: 900;
          }
        }
      }
      .center {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-left: 1px solid;
        border-right: 1px solid;
        width: 60%;
        padding: 0px 20px;
        .top-center {
          .time {
            font-size: 130px;
            font-weight: 900;
            margin-top: -60px;
            color: yellow;
          }
          .blinds {
            display: flex;
            justify-content: space-between;
            padding: 0px 100px;
            color: yellow;
            .text-blind {
              font-size: 50px;
              font-weight: 700;
            }
            .text-level {
              padding-top: 30px;
              font-size: 35px;
              font-weight: 700;
            }
          }
          .level {
            padding-top: -3%;
            font-size: 55px;
            font-weight: 700;
          }
        }
        .footer-center {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .starting-text {
            font-size: 35px;
            font-weight: 400;
          }

          .starting {
            font-size: 45px;
            font-weight: 900;
          }
        }
      }
      .right {
        width: 20%;
        padding-left: 20px;
        .right-text {
          font-size: 35px;
          font-weight: 400;
        }
        .right-value {
          font-size: 45px;
          font-weight: 900;
          margin-top: -10px;
          padding-bottom: 30px;
        }
      }
    }
  }
  @media all and (max-width: $desktop-width-1920) and (min-width: $desktop-width-1440) {
    // @media all and (max-width: $desktop-width-1920) {
    .top {
      height: 25%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: center;
      .title {
        font-size: 45px;
        font-weight: 700;
      }
      .sub-title {
        font-size: 35px;
        font-weight: 500;
      }
      .name-jackpot {
        font-size: 35px;
      }
      .level {
        font-size: 45px;
        font-weight: 900;
      }
    }
    .middle {
      height: 75%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .left {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 20%;
        padding: 0px 20px;
        .top-left {
          height: 80%;
          // overflow: auto;
          .giai {
            font-size: 30px;
            font-weight: 400;
            span {
              font-weight: 900;
            }
          }
        }
        .footer-left {
          height: 20%;
          text-align: right;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          .total-prize-text {
            font-size: 35px;
            font-weight: 500;
          }
          .total-prize {
            font-size: 45px;
            font-weight: 900;
          }
        }
      }
      .center {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-left: 1px solid;
        border-right: 1px solid;
        width: 60%;
        padding: 0px 20px;
        .top-center {
          .time {
            font-size: 180px;
            font-weight: 900;
            margin-top: -50px;
            color: yellow;
          }
          .blinds {
            display: flex;
            justify-content: space-between;
            padding: 0px 100px;
            color: yellow;
            .text-blind {
              font-size: 55px;
              font-weight: 700;
            }
            .text-level {
              padding-top: 25px;
              font-size: 35px;
              font-weight: 700;
            }
          }
          .level {
            margin-top: -50px;
            font-size: 55px;
            font-weight: 700;
          }
        }
        .footer-center {
          display: flex;
          justify-content: space-between;
          .starting-text {
            font-size: 35px;
            font-weight: 400;
          }
          .starting {
            font-size: 45px;
            font-weight: 900;
          }
        }
      }
      .right {
        width: 20%;
        padding-left: 20px;
        .right-text {
          font-size: 35px;
          font-weight: 400;
        }
        .right-value {
          font-size: 45px;
          font-weight: 900;
          margin-top: -10px;
          padding-bottom: 30px;
        }
      }
    }
  }
  @media all and (max-width: $desktop-width-1440) and (min-width: $desktop-width-1098) {
    // @media all and (max-width: $desktop-width-1440) {
    .top {
      height: 25%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: center;
      .title {
        font-size: 35px;
        font-weight: 700;
      }
      .sub-title {
        font-size: 25px;
        font-weight: 500;
      }
      // .name-jackpot {
      //   font-size: 30px;
      // }
      .level {
        // margin-top: 30px;
        font-size: 35px;
        font-weight: 900;
      }
    }
    .middle {
      height: 75%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .left {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 20%;
        padding: 0px 15px;
        .top-left {
          height: 80%;
          .giai {
            font-size: 25px;
            font-weight: 400;
            span {
              font-weight: 900;
            }
          }
        }
        .footer-left {
          height: 20%;
          text-align: right;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          .total-prize-text {
            font-size: 25px;
            font-weight: 500;
          }
          .total-prize {
            font-size: 35px;
            font-weight: 900;
          }
        }
      }
      .center {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-left: 1px solid;
        border-right: 1px solid;
        width: 60%;
        padding: 0px 20px;
        .top-center {
          .time {
            font-size: 160px;
            font-weight: 900;
            margin-top: -40px;
            color: yellow;
          }
          .blinds {
            display: flex;
            justify-content: space-between;
            padding: 0px 100px;
            color: yellow;
            .text-blind {
              font-size: 45px;
              font-weight: 700;
            }
            .text-level {
              padding-top: 25px;
              font-size: 25px;
              font-weight: 700;
            }
          }
          .level {
            margin-top: -50px;
            font-size: 55px;
            font-weight: 700;
          }
        }
        .footer-center {
          display: flex;
          justify-content: space-between;
          .starting-text {
            font-size: 25px;
            font-weight: 400;
          }
          .starting {
            font-size: 35px;
            font-weight: 900;
          }
        }
      }
      .right {
        width: 20%;
        padding-left: 20px;
        .right-text {
          font-size: 25px;
          font-weight: 400;
        }
        .right-value {
          font-size: 35px;
          font-weight: 900;
          margin-top: -10px;
          padding-bottom: 30px;
        }
      }
    }
  }
  @media all and (max-width: $desktop-width-1098) {
    .top {
      height: 25%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: center;
      .title {
        font-size: 30px;
        font-weight: 700;
      }
      .sub-title {
        font-size: 25px;
        font-weight: 500;
      }
      // .name-jackpot {
      //   font-size: 25px;
      // }
      .level {
        font-size: 30px;
        font-weight: 900;
      }
    }
    .middle {
      height: 75%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .left {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 20%;
        padding: 0px 20px;
        .top-left {
          height: 80%;
          .giai {
            font-size: 15px;
            font-weight: 400;
            span {
              font-weight: 900;
            }
          }
        }
        .footer-left {
          height: 20%;
          text-align: right;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          .total-prize-text {
            font-size: 20px;
            font-weight: 500;
          }
          .total-prize {
            font-size: 25px;
            font-weight: 900;
          }
        }
      }
      .center {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-left: 1px solid;
        border-right: 1px solid;
        width: 60%;
        padding: 0px 20px;
        .top-center {
          .time {
            font-size: 140px;
            font-weight: 900;
            margin-top: -40px;
            color: yellow;
          }
          .blinds {
            display: flex;
            justify-content: space-between;
            padding: 0px 100px;
            color: yellow;
            .text-blind {
              font-size: 35px;
              font-weight: 700;
            }
            .text-level {
              padding-top: 20px;
              font-size: 20px;
              font-weight: 700;
            }
          }
          .level {
            margin-top: -50px;
            font-size: 50px;
            font-weight: 700;
          }
        }
        .footer-center {
          display: flex;
          justify-content: space-between;
          .starting-text {
            font-size: 25px;
            font-weight: 400;
          }
          .starting {
            font-size: 30px;
            font-weight: 900;
          }
        }
      }
      .right {
        width: 20%;
        padding-left: 20px;
        .right-text {
          font-size: 20px;
          font-weight: 400;
        }
        .right-value {
          font-size: 30px;
          font-weight: 900;
          margin-top: -10px;
          padding-bottom: 30px;
        }
      }
    }
  }
  .name-jackpot {
    font-size: 1rem;
  }
}
