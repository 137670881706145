#root {
  background-color: #e8eaed;
  min-height: 100vh;
}
body {
  margin: 0px;
  .box-search {
    background-color: white;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 16px;
    .label {
      font-weight: bold;
      font-size: 13px;
      color: #121212;
      padding-bottom: 5px;
    }
  }
}

.ant-picker {
  border: none;
  border-bottom: 1px solid #d9d9d9;
  border-radius: 0px;
  padding: 4px 0px;
}
.ant-picker-focused.ant-picker {
  box-shadow: none;
}
.ant-select-selector {
  border: none !important;
  border-bottom: 1px solid #d9d9d9 !important;
  border-radius: 0px !important;
  padding-left: 0px !important;
  // padding: 4px 0px !important;
}
.ant-select-focused:where(.css-dev-only-do-not-override-2q8sxy).ant-select:not(
    .ant-select-disabled
  ):not(.ant-select-customize-input):not(.ant-pagination-size-changer)
  .ant-select-selector {
  box-shadow: none !important;
}
.ant-input {
  border: none;
  border-bottom: 1px solid #d9d9d9;
  border-radius: 0px;
  padding: 4px 0px;
}
.ant-input:focus {
  box-shadow: none;
}
.ant-input-number-input {
  padding: 1px 0px !important ;
}
.ant-input-number,
.ant-input-number-group-addon {
  border: none !important;
  border-bottom: 1px solid #d9d9d9 !important;
  border-radius: 0px !important;
  padding: 1px 0px !important ;
}
.ant-input-number-affix-wrapper {
  border-width: 0px;
}
.ant-input-number:focus-within {
  box-shadow: none;
}
.ant-input-number:focus {
  //   box-shadow: none;
}
.ant-input-number-handler-wrap {
  display: none !important;
}
.p-accordion-header .p-accordion-header-link {
  padding: 10px;
}

@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .page-break {
    // margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }
}